import * as Sentry from '@sentry/browser';
import posthog from 'posthog-js';
import Raven from 'raven-js';

import {
  filterAccessDenied,
  filterIgnoredDueToLargeOccurrence,
  filterInvariantViolations,
  filterLocalStorageErrors,
  filterNetworkErrors,
  filterResizeObserverErrors,
  filterSyntaxErrors,
  filterVirtualKeyboard,
  fingerprintMathQuillErrors,
  handleCustomEvents,
} from './sentryerrorhandlers';

declare global {
  interface Window {
    SENTRY_JAVASCRIPT_DSN?: string;
    SENTRY_USER_CONTEXT?: Sentry.User;
    RAVEN_USER_CONTEXT?: Record<string, unknown>;
    SITE_VERSION: string;
    INSTRUMENT_JS_GLOBALS: boolean;
    Sentry: typeof Sentry;
    Raven: typeof Raven;
  }
}

const DSN = window.SENTRY_JAVASCRIPT_DSN;
const PROJECT_ID = DSN ? Number(DSN.split('/').slice(-1)[0]) : null;
const SENTRY_ORGANIZATION = 'mathspace';

const BLACKLISTED_URLS = [
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // Wistia assets
  /fast\.wistia\.com/,
];

export function initializeSentry() {
  if (!DSN) return;

  Sentry.init({
    dsn: DSN,
    release: window.SITE_VERSION,
    denyUrls: BLACKLISTED_URLS,
    integrations: PROJECT_ID
      ? [
          new posthog.SentryIntegration(
            posthog,
            SENTRY_ORGANIZATION,
            PROJECT_ID,
          ),
        ]
      : [],
  });

  // Include user information if it's available.
  if (window.SENTRY_USER_CONTEXT) {
    Sentry.configureScope(scope => {
      scope.setUser(window.SENTRY_USER_CONTEXT ?? null);
    });
  }

  Sentry.configureScope(scope => {
    // We will name the entryPoint after the first path segment in the URL.
    // Eg in '/work/a/b/c' it will be 'work', in '/history/a/b/c' it will be 'history'
    scope.setTag('entryPoint', window.location.pathname.split('/')[1]);
    scope.setTag('sentry-sdk', '@sentry/browser');
    scope.addEventProcessor(filterVirtualKeyboard);
    scope.addEventProcessor(filterResizeObserverErrors);
    scope.addEventProcessor(fingerprintMathQuillErrors);
    scope.addEventProcessor(filterInvariantViolations);
    scope.addEventProcessor(filterNetworkErrors);
    scope.addEventProcessor(filterAccessDenied);
    scope.addEventProcessor(filterSyntaxErrors);
    scope.addEventProcessor(filterLocalStorageErrors);
    scope.addEventProcessor(filterIgnoredDueToLargeOccurrence);
    scope.addEventProcessor(handleCustomEvents);
  });

  window.Sentry = Sentry;
}

/**
 * @deprecated
 */
export const initializeRaven = () => {
  if (!DSN) return;

  const legacyMeta = document.querySelector('meta[name=legacy-mathspace]');
  if (legacyMeta == null) return;

  // msproblem legacy code relies on raven being available and initialized.
  Raven.config(DSN, {
    release: window.SITE_VERSION,
    ignoreUrls: BLACKLISTED_URLS,
    autoBreadcrumbs: window.INSTRUMENT_JS_GLOBALS,
    instrument: window.INSTRUMENT_JS_GLOBALS,
    debug: true,
  }).install();
  if (!window.Raven) window.Raven = Raven;
  if (window.RAVEN_USER_CONTEXT) {
    Raven.setUserContext(window.RAVEN_USER_CONTEXT);
  }

  Raven.setTagsContext({
    // We will name the entryPoint after the first path segment in the URL.
    // Eg in '/work/a/b/c' it will be 'work', in '/history/a/b/c' it will be 'history'
    entryPoint: window.location.pathname.split('/')[1],
    'sentry-sdk': 'raven-js',
  });

  Raven.setShouldSendCallback(ravenSendCallbackHandler);
};

const ravenSendCallbackHandler = (
  event: Sentry.Event,
): Sentry.Event | null | undefined => {
  const functions = [
    filterResizeObserverErrors,
    fingerprintMathQuillErrors,
    filterInvariantViolations,
    filterLocalStorageErrors,
    filterNetworkErrors,
    filterAccessDenied,
    filterSyntaxErrors,
    filterIgnoredDueToLargeOccurrence,
  ];
  let result: Sentry.Event | null | undefined = null;

  for (let fn of functions) {
    result = fn(event);
    if (result == null) return result;
  }

  return result;
};
