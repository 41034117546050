import '@babel/polyfill';
import { polyfill } from 'smoothscroll-polyfill';

import { initializeLinkedInInsightTag } from 'ms-helpers/LinkedInInsightTag';
import { initializePosthog } from 'ms-helpers/Posthog';

import { initializeRaven } from './sentry';

polyfill();

// Inject the CDN path into Webpack for runtime loading.
//
// This helps Webpack load files that are emitted at compile-time (via
// file-loader, url-loader, etc) even though we will not know the domain until
// later, after deployment.
//
// See the note on `publicPath`:
// https://webpack.github.io/docs/configuration.html#output-publicpath
//
// Also see file-loader for an example on emitting files:
// https://github.com/webpack/file-loader
//
// eslint-disable-next-line
__webpack_public_path__ = window.WEBPACK_PUBLIC_PATH;

initializeRaven();
initializePosthog();
initializeLinkedInInsightTag();
