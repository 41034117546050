const AD_TRACKING_ENABLED = 'ms.adtracking.enabled';

export function initializeLinkedInInsightTag() {
  if (!window.localStorage) {
    return;
  }
  // We only want to use the LinkedIn Insight Tag to track campaign
  // conversions, so we only want it enabled if the user came from
  // a campaign.
  if (window.localStorage.getItem(AD_TRACKING_ENABLED)) {
    runLinkedInInsightTag();
  }
}

export function captureTeacherSignup() {
  // We don't want to pollute the actual Window interface with this linkedin crap
  // so we'll just disable typechecking on the window object by using this alias.
  const _window = window as any;
  if (_window.lintrk) {
    // See https://www.linkedin.com/campaignmanager/accounts/507401620/website-tracking/conversions
    _window.lintrk('track', { conversion_id: 8369916 });
  }
}

function runLinkedInInsightTag() {
  // We don't want to pollute the actual Window interface with this linkedin crap
  // so we'll just disable typechecking on the window object by using this alias.
  const _window = window as any;

  // Pasted from the LinkedIn Insight Tag code: https://www.linkedin.com/campaignmanager/accounts/507401620/insight-tag
  var _linkedin_partner_id = '2611764';
  _window._linkedin_data_partner_ids = _window._linkedin_data_partner_ids || [];
  _window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  (function (l) {
    if (!l) {
      _window.lintrk = function (a: unknown, b: unknown) {
        _window.lintrk.q.push([a, b]);
      };
      _window.lintrk.q = [];
    }
    var s = document.getElementsByTagName('script')[0];
    var b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s?.parentNode?.insertBefore(b, s);
  })(_window.lintrk);
}
