import posthog from 'posthog-js';

type Posthog = typeof posthog;
type PosthogIdentity = {
  id: string;
  attributes: Record<string, any>;
};

declare global {
  // We need to use an interface to extend the Window type,
  // intersection won't let us use the same name.
  interface Window {
    POSTHOG_API_KEY?: string;
    POSTHOG_HOST?: string;
    POSTHOG_IDENTITY: PosthogIdentity;
    posthog?: Posthog;
  }
}

const POSTHOG_API_KEY = window.POSTHOG_API_KEY || '';
const POSTHOG_HOST = window.POSTHOG_HOST || '';
const POSTHOG_IDENTITY = window.POSTHOG_IDENTITY || {};
const POSTHOG_RECORDING_PROBABILITY = 0.02;

const POSTHOG_IDENTITY_KEY = 'ms.posthog.identity';
const POSTHOG_RECORDING_KEY = 'ms.posthog.recording';

export function initializePosthog() {
  if (!POSTHOG_API_KEY || !POSTHOG_HOST) {
    return;
  }

  if (!window.sessionStorage || !window.localStorage) {
    return;
  }

  if (window.location.pathname.startsWith('/admin')) {
    return;
  }

  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_HOST,
    autocapture: true,
    capture_pageview: true,
    disable_session_recording: !determineSessionRecording(
      window.sessionStorage,
    ),
    // Changed from the default value due to large payload size that was triggering "Header Too Large" errors
    persistence: 'localStorage+cookie',
    session_recording: {
      // Elements with this class will be blocked out in session recording
      blockClass: 'block-tracking',
    },
    enable_recording_console_log: true,
    loaded: posthog =>
      handlePosthogLoaded(posthog, window.localStorage, POSTHOG_IDENTITY),
  });
}

export function capturePageView() {
  if (window.posthog) {
    window.posthog.capture('$pageview');
  }
}

function handlePosthogLoaded(
  posthog: Posthog,
  localStorage: Storage,
  identity: PosthogIdentity,
): void {
  const identityAsString = JSON.stringify(identity);

  const lastIdentity = localStorage.getItem(POSTHOG_IDENTITY_KEY);
  const identityChanged = lastIdentity !== identityAsString;

  // Update the identity in Posthog only if it has changed, to reduce
  // unnecessary requests.
  if (identityChanged) {
    localStorage.setItem(POSTHOG_IDENTITY_KEY, identityAsString);

    if (identity.id) {
      // We can't use dates as person property (yet). In order to apply
      // range queries, we must use numbers. So we'll convert the date
      // to a number, where whole part is the date and the fraction is
      // the time.
      const now = Number(
        new Date()
          .toISOString()
          .replace(/[-:.Z]/g, '')
          .replace(/T/, '.'),
      );
      // Attaches identity data to subsequent Posthog events.
      posthog.identify(
        identity.id,
        // "$set" person properties
        identity.attributes,
        // "$set_once" person properties (don't overwrite existing values)
        {
          first_seen: now,
          first_login: now,
        },
      );
    } else {
      // Detaches identity data so the user becomes anonymous. This
      // should get triggered on logout.
      posthog.reset(true);
    }
  }
  window.posthog = posthog;
}

function determineSessionRecording(sessionStorage: Storage) {
  // We want to selectively enable session recording, so we decide this
  // on the initiation of a new session, and remember it for future
  // page loads of the same session -- or we'll end up with partial
  // session recordings.
  let shouldRecord = false;
  const lastRecordingState = sessionStorage.getItem(POSTHOG_RECORDING_KEY);

  // Using sessionStorage is important: it empties with each new session,
  // so we can relay on that to detect a new session.
  const isNewSession = lastRecordingState === null;
  if (window.location.pathname.startsWith('/accounts/signup')) {
    // If on the signup page, always start a recording
    shouldRecord = true;
    // And remember that we're recording for subsequent page views
    sessionStorage.setItem(POSTHOG_RECORDING_KEY, shouldRecord ? '1' : '0');
  } else if (isNewSession) {
    // Starting a new page session;
    // Decide on whether to turn on session recording
    shouldRecord = Math.random() <= POSTHOG_RECORDING_PROBABILITY;
    sessionStorage.setItem(POSTHOG_RECORDING_KEY, shouldRecord ? '1' : '0');
  } else {
    // Not starting a new page session;
    // Continue with last known recording state
    shouldRecord = lastRecordingState === '1';
  }
  return shouldRecord;
}
