import * as Sentry from '@sentry/browser';

// Types from @sentry/types
type Event = Sentry.Event;
type EventHint = Sentry.EventHint;

export function filterResizeObserverErrors(event: Event): Event | null {
  // Chrome appears to have a stale RO implementation that is not spec-compliant.
  // This particular error string (and associated algorithm for loop detection)
  // was removed from the spec way back in 2016. Going to assume this is an
  // internal browser bug for now.
  if (event.exception && event.exception.values) {
    const values = event.exception.values.map(v => v.value);

    const errorStrings = [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ];

    for (let errorString of errorStrings) {
      if (values.includes(errorString)) {
        // returning null cancels the event.
        return null;
      }
    }
  }

  return event;
}

// The mathquill library throws a large number of errors regularly. In order to keep
// our sentry logs clean, we bundle all the mathquill related errors into one "set".
// Sentry uses fingerprints to determine the identity of an error and groups by them.
export function fingerprintMathQuillErrors(event: Event): Event | null {
  const stackHasReferenceToMathquill = (_event: Event) => {
    const stackTraceFrames = _event.exception?.values?.[0]?.stacktrace?.frames;
    return (
      Array.isArray(stackTraceFrames) &&
      stackTraceFrames.some(
        frame =>
          (typeof frame.filename === 'string' &&
            frame.filename.includes('mathquill')) ||
          (typeof frame.module === 'string' &&
            frame.module.includes('mathquill')),
      )
    );
  };

  const isMathQuillError = stackHasReferenceToMathquill(event);

  // Identify errors triggered by handwriting panel attempting to execute
  // null as a function.
  const errorMessage = event.exception?.values?.[0]?.value;
  const isNullNotAFunctionError =
    typeof errorMessage === 'string' &&
    errorMessage.includes('(null) is not a function');

  return {
    ...event,
    fingerprint: isMathQuillError
      ? ['mathquill']
      : isNullNotAFunctionError
      ? ['nullNotAFunctionError']
      : event.fingerprint ?? [],
  };
}

export function filterInvariantViolations(event: Event): Event | null {
  // Filter out Invariant Violations, so we don't blow the sentry quota
  // This should allow us to then slowly turn these back on so that we can
  // Start actioning these properly
  if (event.exception && event.exception.values) {
    const types = event.exception.values.map(v => v.type);
    if (types.includes('InvariantViolation')) return null;
  }
  return event;
}

export function filterNetworkErrors(event: Event): Event | null {
  // Filter out Network Errors as we can't do anything about them
  if (event.exception && event.exception.values) {
    for (let evt of event.exception.values) {
      const isNetworkError =
        evt.value?.includes('NetworkError') ||
        evt.value?.includes('Failed to fetch') ||
        evt.value?.includes('A network error occurred') ||
        evt.value?.includes('Network failure') ||
        evt.value?.includes('The network connection was lost.') ||
        evt.type === 'NetworkError';

      if (isNetworkError) {
        return null;
      }
    }
  }
  return event;
}

export function filterSyntaxErrors(event: Event): Event | null {
  // Filter out Network Errors as we can't do anything about them
  if (event.exception && event.exception.values) {
    const types = event.exception.values.map(v => v.type);
    if (types.includes('SyntaxError')) return null;
  }
  return event;
}

export function filterAccessDenied(event: Event): Event | null {
  if (event.exception && event.exception.values) {
    const values = event.exception.values.map(v => v.value);
    if (values.includes('Access Denied')) return null;
  }
  return event;
}

export function filterLocalStorageErrors(event: Event): Event | null {
  if (event.exception && event.exception.values) {
    const values = event.exception.values.map(v => v.value);
    if (values.includes('localStorageDb: Impossible to set value')) return null;
  }
  return event;
}

export function filterIgnoredDueToLargeOccurrence(event: Event): Event | null {
  // These errors are being silenced whilst we get sentry sorted out
  // They produce so many events that we exhaust the sentry quota
  if (event.exception && event.exception.values) {
    for (let value of event.exception.values) {
      // TypeError: null is not an object (evaluating 'l.clearRect')
      if (value.value?.includes('clearRect')) {
        return null;
      }

      // TypeError: cancelled
      if (value.value === 'cancelled') {
        return null;
      }

      // TypeError: cancelled
      if (value.value?.includes('(null) is not a function')) {
        return null;
      }
    }
  }
  return event;
}

export function handleCustomEvents(
  event: Event,
  hint: EventHint | undefined,
): Event | null {
  const capturedObj = hint?.originalException;

  // handle CustomEvents which have real Errors buried inside them
  if (
    capturedObj instanceof CustomEvent &&
    capturedObj.detail &&
    capturedObj.detail.reason &&
    capturedObj.detail.reason instanceof Error
  ) {
    Sentry.withScope(() => {
      // pull out the embedded Error object
      const buriedError = capturedObj.detail.reason;
      capturedObj.detail.reason = '<error object captured above>';

      // capture the rest of the CustomEvent's data as extra
      Sentry.setExtras({
        originalThrownObject: capturedObj,
      });

      // create a new event out of the real Error, annotated with the other data
      Sentry.captureException(buriedError);
    });

    // don't send the original, badly-structured event
    return null;
  }

  return event;
}

export function filterVirtualKeyboard(event: Event): Event | null {
  if (event?.exception?.values) {
    const values = event.exception?.values.map(v => v.value);
    const isKeyboardError = values.some(f => f?.includes('virtualKeyboard'));
    if (isKeyboardError) {
      return null;
    }
  }

  return event;
}
